// ########## COLORS #########
::root{
    --primary: '#0A365C';
    --econdary: '#82D209';
}

// ########## FONTS #########
// -> font poppins
@font-face {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../fonts/poppins.woff') format('woff'),
    url('../fonts/poppins.woff2') format('woff2'),
}

// ########## GENERAL #########
* {
    font-family: 'poppins' !important;
}

html, body{
    // background: rgba(130, 210, 9, 0.05);
    padding: 0;
    margin: 0;
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(10, 54, 92, .8); 
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(10, 54, 92, .6); 
    }
}

a{
    text-decoration: none !important;
    color: var(--primary);
}

.neo {
    border-radius: 10px !important;
    justify-content: center !important;
    transition: all ease-in-out 0.3s !important;
}

.neo-bulge {
    box-shadow:
        12px 12px 16px 0 rgba(0, 0, 0, .1) inset,
        -8px -8px 12px 0 rgba(0, 0, 0, .1) inset !important;
}
.neo-down {
    box-shadow:
        inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
        inset -6px -6px 10px 0 rgb(10, 54, 92, .2) !important;
}
.neo-up{
    box-shadow: 6px 6px 16px 0 rgba(0, 0, 0, 0.1),
        -2px -8px 20px 12px rgba(130, 210, 9, .5) !important;
}

.neo-up-2{
    box-shadow: 6px 6px 38px 0 rgba(0, 0, 0, 0.2),
        -1px -8px 30px 2px rgb(10, 54, 92, .2) !important;
}

// ############# Buttons #################
.app-btn{
    box-shadow: none !important;
    border-radius: 5px !important;
    text-transform: capitalize !important;
}
.dumy{
    color: rgba(130, 210, 9, 0.502);
}

// ############ Alert #############
.error-text{
    font-size: 12px;
    color: red;
}